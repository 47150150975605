<template>
    <div>
        <div class="banner">
            <img src="@/assets/image/terms/banner2.png" alt=""/>
        </div>
        <div>
            <div class="slogan">Get familiar with the policies, terms, and conditions for the purchase, support, and servicing of <strong>HOLOWITS</strong> products.</div>
        </div>
        <div class="title">
            <p>{{$i18n.t("terms.title1")}}<img src="@/assets/image/terms/technical_support.png"/></p>
        </div>
        <div class="list">
            <div class="item">
                <div class="t-img">
                    <img src="@/assets/image/terms/product_warranty.png" alt="">
                </div>
                <div class="t-label">{{$i18n.t("terms.title2")}}</div>
                <div class="t-link">
                    <a @click="toPage('/warrantypolicy')">{{$i18n.t("terms.jumpPrompt")}}<i class="el-icon-arrow-right"></i></a>
                </div>
            </div>
            <div class="item">
                <div class="t-img">
                    <img src="@/assets/image/terms/product_warranty.png" alt="">
                </div>
                <div class="t-label">HOLOWITS RMA Policy</div>
                <div class="t-link">
                    <a @click="toPage('/terms/rma')">Find the RMA Policy<i class="el-icon-arrow-right"></i></a>
                </div>
            </div>
        </div>
        <!-- <div class="title">
            <p>Partner<img src="@/assets/image/terms/partner.png"/></p>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'Terms',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        toPage(path) {
            this.$router.push({
                path
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.banner {
    width: 100%;
    img {
        width: 100%;
    }
}
.slogan {
    font-size: 20px;
    text-align: center;
    padding: 50px 0px;
    width: 610px;
    margin: auto;
    strong {
        font-size: 26px;
        color: #c7000b;
    }
}

.title {
    padding: 50px 0; 
    border-top: 1px solid #ebeef5; 
    border-bottom: 1px solid #ebeef5;
    p {
        font-size: 26px; 
        font-weight: 600; 
        text-align: center;
        img {
            margin-left: 10px; 
            width: 45px; 
            height: 45px;
        }
    }
}
.list {
    width: 980px;
    margin: 0px auto;
    overflow: hidden;
    .item {
        float: left;
        width: 50%;
        margin: 35px 0;
        padding: 0px 80px;
        &:nth-child(odd) {
            border-right: 1px solid #ebeef5;
        }
        .t-img {
            text-align: center;
            img {
                width: 45px;
                height: 45px;
            }
        }
        .t-label {
            text-align: center;
            word-break: break-word;
            font-size: 16px;
            font-weight: 600;
        }
        .t-link {
            margin-top: 20px;
            text-align: center;
            a {
                color: #c7000b;
                cursor: pointer;
            }
        }
    }
}
</style>